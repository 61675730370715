export default {
  colors: {
    primary: '#906EEE',
    background: '#FFFFFF',
    shape: `#EFEAFD`,
    title: `#000000`,
    text: `#707070`,
  },
}

export type Theme = {
  colors: {
    primary: string
    background: string
    shape: string
    title: string
    text: string
  }
}
